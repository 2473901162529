import React, { useContext } from 'react';
import "./Home.scss";
import "./HomePage.scss";

import ThemeContext from '../../context/ThemeContext';

import { Link } from "react-router-dom";

import { translate } from 'react-switch-lang';

import { isMobile } from "react-device-detect";

import { Grid, Icon, Button, Segment} from "semantic-ui-react";
import FunnySentences from '../FS';


function Home(props) {

    const { t } = props;

    const { theme } = useContext(ThemeContext);

    return(
        <section id="contentHome">
            <Grid className="noMargin">

                <Grid.Column width={16} className={theme === "dark" ? "contentHomeGrid contentHomeGrid__dark" : "contentHomeGrid contentHomeGrid__light"}>

                    <div className='helloWorldDiv'>{t('HELLO_WORLD')}</div>

                    <FunnySentences />
                    {/* <div className="titleHomePage">{t('TITLE_HOME_PAGE')}</div>
                    <div className="subTitleHomePage" style={{ width: isMobile ? "90%" : 800 }}>
                        {t('SUBTITLE_HOME_PAGE')}
                    </div>
                    <Grid className="gridHomePage">
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon.Group size='huge'>
                                <Icon corner className="cornerLeft" name='tablet alternate' />
                                <Icon name='desktop' />
                                <Icon corner className="cornerRight" name='mobile alternate' />
                            </Icon.Group>
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_1')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_1')}
                            </div>
                        </Grid.Column>
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon size="huge" name='code' />
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_2')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_2')}
                            </div>
                        </Grid.Column>
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon size="huge" name='mobile alternate' />
                            <Icon size="huge" name='android' />
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_3')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_3')}
                            </div>
                        </Grid.Column>
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon size="huge" name='desktop' />
                            <Icon size="huge" name='windows' />
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_4')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_4')}
                            </div>
                        </Grid.Column>
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon size="huge" name='language' />
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_5')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_5')}
                            </div>
                        </Grid.Column>
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon size="huge" name='eye' />
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_6')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_6')}
                            </div>
                        </Grid.Column>
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon size="huge" name='database' />
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_7')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_7')}
                            </div>
                        </Grid.Column>
                        <Grid.Column className={theme === "dark" ? "gridColumnHomePage gridColumnHomePage__dark" : "gridColumnHomePage gridColumnHomePage__light"} width={2} style={{ minWidth: isMobile ? "100%" : 400 }}>
                            <Icon size="huge" name='hdd' />
                            <div className="titleColumnGrid">{t('COLUMN_HOME_PAGE_8')}</div>
                            <div className="contentColumnGrid">
                                {t('CONTENT_COLUMN_HOME_PAGE_8')}
                            </div>
                        </Grid.Column>
                    </Grid> */}
                </Grid.Column>
            </Grid>
            {/* <div className="buttonDiv">
                <Button as={Link} to="/contact" className={theme === "dark" ? "buttonHome buttonHomen__dark" : "buttonHome buttonHome__light"}>
                    {t('BUTTON_BUDGET')}
                </Button>
            </div>
            <div className="segmentDiv">
                <Segment compact className={theme==="dark" ? "segmentHome segmentHome__dark" : "segmentHome segmentHome__light"}>
                    <div>{t('EXTRA_SENTENCE_1')}</div>
                    <div>{t('EXTRA_SENTENCE_2')}</div>
                    <div>{t('EXTRA_SENTENCE_3')}</div>
                </Segment>
            </div> */}
        </section>
    );

}

export default translate(Home);

import React, { useContext } from 'react'
import { Button } from 'semantic-ui-react';
import ThemeContext from './ThemeContext'
import { ReactComponent as MoonIcon } from '../assets/images/moon.svg';
import { ReactComponent as SunIcon } from '../assets/images/sun.svg';
import "./ThemeSwitcher.scss";
// <img src={theme == "dark" ? Sun : Moon} className="theme-icon" alt="theme" />

function ThemeSwitcher() {
  const { theme, setTheme } = useContext(ThemeContext);

    //usar localstorage
  const setThemeAndSave = () => {
    let aux_theme = "light";
    if(theme === "light") {
      aux_theme = "dark";
    }
    setTheme(theme === "light" ? "dark" : "light");
    localStorage.setItem('themeCVTomas', aux_theme);
  }

  return (
    <Button
      onClick={() => setThemeAndSave()}
      className={theme === "dark" ? "button-theme button-theme__dark" : "button-theme button-theme__light"}
    >
      <SunIcon className={theme === "dark" ? "svg svg__dark" : "svg svg__light"} />
      <MoonIcon className={theme === "dark" ? "svg svg__dark" : "svg svg__light"} />
    </Button>  
  )
}

export default ThemeSwitcher
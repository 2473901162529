import React, { useContext } from 'react';
import { translate } from 'react-switch-lang';

import { isMobile } from "react-device-detect"; 

import ScrollAnimation from 'react-animate-on-scroll';

import ThemeContext from '../../context/ThemeContext';

import { Grid, Icon } from 'semantic-ui-react';

function ExpLab(props) {

    const { t } = props;

    const { theme } = useContext(ThemeContext);

    return (
        <Grid className={isMobile ? theme==="dark" ? "noMargin gridMobile gridMobile__dark" : "noMargin gridMobile gridMobile__light" : "noMargin minHeightGrid"}>
            <Grid.Column style={{ paddingRight: isMobile ? 10 : 50, paddingTop: isMobile ? 50 : 20 }} width={isMobile ? 16 : 5} id="gridColumn__ExpLab" className={theme === "dark" ? "gridColumnContent__1 gridColumnContent__1__dark_secondary" : "gridColumnContent__1 gridColumnContent__1__light_secondary"}>
                <ScrollAnimation animateIn="slideInRight" animateOnce={true} delay={200}>
                {!isMobile &&
                    <>
                        <div className="strongText titleGridColumm">{t('EXP_LAB')}</div>
                        <div>{t('EXP_LAB_SUB')}</div>
                        <Icon name="building" className={theme === "dark" ? "absoluteIconGrid absoluteIconGrid__dark" : "absoluteIconGrid absoluteIconGrid__light"} />
                    </>
                }
                {isMobile &&
                    <div className="gridMobileContent">
                        <div className="strongText titleGridColumm titleGridColummMobile">{t('EXP_LAB')}</div>
                        <div>{t('EXP_LAB_SUB')}</div>
                        <Icon name="building" className={theme === "dark" ? "absoluteIconGridMobile absoluteIconGridMobile__dark" : "absoluteIconGridMobile absoluteIconGridMobile__light"} />
                    </div>
                }
                </ScrollAnimation>
            </Grid.Column>
            <Grid.Column width={isMobile ? 16 : 11} className={theme === "dark" ? "gridColumnContent__2 gridColumnContent__2__dark_secondary" : "gridColumnContent__2 gridColumnContent__2__light_secondary"}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={200}>
                <Grid className={isMobile ? "gridExp gridExp__Mobile" : "gridExp gridExp__PC"}>
                    <Grid.Column width={isMobile ? 16 : 5} className="gridInfoExp">
                        <div className="smallCircle" />
                        <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                            <div className="expDiv">
                                <div className="timeExp">2014 - 2020</div>
                                <div className="titleExpDiv">BotGenes S.R.L. </div>
                                <div className="puestoExpDiv"><Icon name="user" />Senior Fullstack Developer</div>
                                <div className="ubicationExpDiv"><Icon name="map marker alternate" />San Isidro, Buenos Aires. </div>
                            </div>
                        </span>
                    </Grid.Column>
                    <Grid.Column width={isMobile ? 16 : 11} className="gridInfoExp">
                        <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                            <div className="tasksWorkDiv">
                                <div className="descriptionWork"><Icon name="building" />{t('CONTENT_EXPLAB_1')}</div>
                                <div className="tasksWork"><Icon name="tasks" /><strong>{t('TASKS')}</strong>: {t('TASKS_EXPLAB_1')} </div>
                            </div>
                        </span>
                    </Grid.Column>
                </Grid>
                <Grid className={isMobile ? "gridExp gridExp__Mobile" : "gridExp gridExp__PC"}>
                    <Grid.Column width={isMobile ? 16 : 5} className="gridInfoExp">
                        <div className="smallCircle" />
                        <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                            <div className="expDiv">
                                <div className="timeExp">2015 - {t('PRESENT')}</div>
                                <div className="titleExpDiv">Ministerio de Seguridad - Dirección de Sanidad</div>
                                <div className="puestoExpDiv"><Icon name="user" />{t('ADMINISTRATIVE')}</div>
                                <div className="ubicationExpDiv"><Icon name="map marker alternate" />Berazategui, Buenos Aires. </div>
                            </div>
                        </span>
                    </Grid.Column>
                    <Grid.Column width={isMobile ? 16 : 11} className="gridInfoExp">
                        <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                            <div className="tasksWorkDiv">
                                <div className="descriptionWork"><Icon name="building" />{t('CONTENT_EXPLAB_2')}</div>
                                <div className="tasksWork"><Icon name="tasks" /><strong>{t('TASKS')}</strong>: {t('TASKS_EXPLAB_2')} </div>
                            </div>
                        </span>
                    </Grid.Column>
                </Grid>

                <Grid className={isMobile ? "gridExp gridExp__Mobile" : "gridExp gridExp__PC"}>
                    <Grid.Column width={isMobile ? 16 : 5} className="gridInfoExp">
                        <div className="smallCircle" />
                        <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                            <div className="expDiv">
                                <div className="timeExp">2020 - {t('PRESENT')}</div>
                                <div className="titleExpDiv">Servicios de programación freelance</div>
                                <div className="puestoExpDiv"><Icon name="user" />Fullstack Developer Freelancer</div>
                                <div className="ubicationExpDiv"><Icon name="map marker alternate" />Vicente Lopez, Buenos Aires. </div>
                            </div>
                        </span>
                    </Grid.Column>
                    <Grid.Column width={isMobile ? 16 : 11} className="gridInfoExp">
                        <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                            <div className="tasksWorkDiv">
                                <div className="descriptionWork"><Icon name="building" />{t('CONTENT_EXPLAB_3')}</div>
                                <div className="tasksWork"><Icon name="tasks" /><strong>{t('TASKS')}</strong>: {t('TASKS_EXPLAB_3')} </div>
                            </div>
                        </span>
                    </Grid.Column>
                </Grid>
                </ScrollAnimation>
            </Grid.Column>
        </Grid>
    )
}

export default translate(ExpLab);
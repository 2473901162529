import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-switch-lang';

import Recaptcha from "react-recaptcha";

import axios from 'axios';

import { isMobile } from "react-device-detect";

import Global from "./Global";

import { validateEmail } from "./ValidationEmail";

import ThemeContext from '../../context/ThemeContext';

import { Grid, Header, Form, Input, TextArea, Message, Button } from "semantic-ui-react";

import "./Contact.scss";

function Contact(props) {

    const { t, recaptchaInstance } = props;

    useEffect(() => {
        window.scroll({top: 280, left: 0, behavior: 'smooth' });
    }, []);

    const { theme } = useContext(ThemeContext);

    const [formData, setFormData] = useState({
        "name": "",
        "country": "",
        "email": "",
        "subject": "",
        "comments": "",
        "budget": "",
        "recaptcha": false,
        "token": ""
    });

    const [isError, setIsError] = useState(false);
    const [messageError, setMessageError] = useState("");
    const [formError, setFormError] = useState({});
    const [showSubmited, setShowSubmited] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const verifyCaptcha = (response) => {
        setFormData(formData => ({ ...formData, recaptcha: true, token: response }));
    }

    const expiredCaptcha = () => {
        setFormData(formData => ({ ...formData, recaptcha: false, token: "" }));
    }

    const onSubmit = () => {
        console.log(formData);
        setIsSending(true);
        if(!formData.name) {
            setFormError({ name: true });
            setIsError(true);
            setMessageError("ERROR_BAD_NAME");
            setIsSending(false);
        } else if(!formData.country) {
            setFormError({ country: true });
            setIsError(true);
            setMessageError("ERROR_BAD_COUNTRY");
            setIsSending(false);
        } else if (!formData.email || (!validateEmail(formData.email))) {
            setFormError({ email: true });
            setIsError(true);
            setMessageError("ERROR_BAD_EMAIL");
            setIsSending(false);
        } else if (!formData.comments) {
            setFormError({ comments: true });
            setIsError(true);
            setMessageError("ERROR_EMPTY_COMMENTS");
            setIsSending(false);
        } else if(!formData.recaptcha) {
            setIsError(true);
            setMessageError("ERROR_RECAPTCHA");
            setIsSending(false);
        } else {
            axios.post(Global.url + 'sendmessage', formData)
                .then(res => {
                    if(res.data.result === "OK") {
                        setShowSubmited(true);
                        setIsError(false);
                    } else if(res.data.result === "EMAIL") {
                        setFormError({ email: true });
                        setIsError(true);
                        setMessageError("ERROR_BAD_EMAIL");
                    } else if(res.data.result === "RECAPTCHA") {
                        setIsError(true);
                        setMessageError("ERROR_RECAPTCHA");
                        setIsSending(false);
                    } else if(res.data.result === "COMMENTS") {
                        setFormError({ comments: true });
                        setIsError(true);
                        setMessageError("ERROR_EMPTY_COMMENTS");
                    } else if(res.data.result === "COUNTRY") {
                        setFormError({ country: true });
                        setIsError(true);
                        setMessageError("ERROR_BAD_COUNTRY");
                    } else if(res.data.result === "ERROR_EMAIL") {
                        setIsError(true);
                        setMessageError("ERROR_SERVER_CONTACT");
                    } else {
                        console.log(res.data);
                        setIsError(true);
                        setMessageError("ERROR_SERVER_BAD_NAMES");
                    }
                }).catch(err => {
                    setIsError(true);
                    setMessageError("ERROR_SERVER_CONTACT");
                }).finally(() => {
                    setIsSending(false);
                });
        }
    }

    return (
        <Grid className="noMargin minHeightGrid">
            <Grid.Column width={16} id="gridColumn__Contact" className={theme === "dark" ? "gridContact gridColumnContent__2 gridColumnContent__2__dark textStyle__dark" : "gridContact gridColumnContent__2 gridColumnContent__2__light textStyle__light"}>
                <Header as="h2" className={theme === "dark" ? "titleContact textStyle__dark" : "titleContact textStyle__light"}>
                    {t('FORM_CONTACT')}
                </Header>
                <div className={isMobile ? "contentContact contentContact__Mobile" : "contentContact contentContact__PC"}>
                    <Grid stackable>
                        <Grid.Column width={16}>
                            <div className="gridContact">
                                <div className="textContact">{t('CONTACT_TEXT')}</div>
                                <div className="formContact">
                                <Form onSubmit={onSubmit} className={theme === "dark" ? "formStyle formStyle__dark" : "formStyle formStyle__light"}>
                                    <Form.Field
                                        required
                                        id='inputName'
                                        control={Input}
                                        label={t('NAME')}
                                        placeholder={t('NAME')}
                                        className={theme === "dark" ? "inputStyle inputStyle__dark" : "inputStyle inputStyle__light"}
                                        error={formError.name}
                                        name="name"
                                        onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                    />
                                    <Form.Field
                                        required
                                        id='inputCountry'
                                        control={Input}
                                        label={t('COUNTRY')}
                                        placeholder={t('COUNTRY')}
                                        className={theme === "dark" ? "inputStyle inputStyle__dark" : "inputStyle inputStyle__light"}
                                        error={formError.email}
                                        name="country"
                                        onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                    />
                                    <Form.Field
                                        required
                                        id='inputEmail'
                                        control={Input}
                                        label='Email'
                                        placeholder='Email'
                                        className={theme === "dark" ? "inputStyle inputStyle__dark" : "inputStyle inputStyle__light"}
                                        error={formError.email}
                                        name="email"
                                        onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                    />
                                    <Form.Field
                                        id='form-textarea-control-subject'
                                        control={Input}
                                        label={t('WHAT_NEED_YOU')}
                                        placeholder={t('WHAT_NEED_YOU')}
                                        className={theme === "dark" ? "inputStyle inputStyle__dark" : "inputStyle inputStyle__light"}
                                        error={formError.comments}
                                        name="subject"
                                        onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                    />
                                    <Form.Field
                                        id='inputBudget'
                                        control={Input}
                                        label={t('THINK_BUDGET')}
                                        placeholder={t('THINK_BUDGET')}
                                        className={theme === "dark" ? "inputStyle inputStyle__dark" : "inputStyle inputStyle__light"}
                                        error={formError.email}
                                        name="budget"
                                        onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                    />
                                    <Form.Field
                                        id='form-textarea-control-comments'
                                        control={TextArea}
                                        rows="10"
                                        label={t('COMMENTS')}
                                        placeholder={t('COMMENTS_PLACEHOLDER')}
                                        className={theme === "dark" ? "inputStyle inputStyle__dark" : "inputStyle inputStyle__light"}
                                        error={formError.comments}
                                        name="comments"
                                        required
                                        onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                    />
                                    <div className="divRecaptcha marginCenter">
                                        <div className="reCaptchaDiv__text">{t('PLEASE_CHECK_RECAPTCHA')}</div>
                                        <div className="reCaptchaDiv">
                                            <Recaptcha
                                                sitekey="6LfsSKcZAAAAAJs4y-LjXWNWeIurUsLoDVDH0VBK"
                                                verifyCallback={verifyCaptcha}
                                                expiredCallback={expiredCaptcha}
                                                theme={theme}
                                                render="explicit"
                                                ref={recaptchaInstance}
                                            />
                                        </div>
                                        <Form.Field
                                            id='form-button-control-public'
                                            control={Button}
                                            content={t('SEND_MESSAGE')}
                                            className={theme === "dark" ? "formBtn formBtn__dark" : "formBtn formBtn__light"}
                                            loading={isSending}
                                        />
                                        {isError &&
                                            <Message className="messageSegment messageSegment__error">
                                                <p>{t(messageError)}</p>
                                            </Message>
                                        }
                                        {showSubmited &&
                                            <Message className="messageSegment messageSegment__submited">
                                                <p>{t('MESSAGE_CONTACT_SENT')}</p>
                                            </Message>
                                        }
                                    </div>
                                </Form>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                </div>
            </Grid.Column>
        </Grid>
    )
}

export default translate(Contact);

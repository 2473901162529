import React, { useState } from 'react';
import funnySentences from '../utils/json/FS.json';
import { Card, Button } from 'semantic-ui-react';
import Typing from 'react-typing-animation';


export default function FunnySentences() {
    let aux;
    const [sentence, setSentence] = useState(-1);
    const changeSentence = () => {
        const values = Object.keys(funnySentences);
        aux = values[parseInt(Math.random() * values.length)];
        while(aux === sentence) {
            aux = values[parseInt(Math.random() * values.length)];
        }
        setSentence(aux);
    }

    const skipCode = () => {
        setSkip(true);
        setDisabled(false);
    }

    const [disabled, setDisabled] = useState(true);
    const [skip, setSkip] = useState(false);

    return (
        <>
            {CardTyping(codeShow, setDisabled, skip, setSkip)}
            {sentence !== -1 && (
            <div className="showSentence">
                {funnySentences[sentence]}
            </div>
            )}
            <div className="groupButtons">
                <Button className="btnChangeSentence btnChangeSentenceSkip" disabled={skip} onClick={skipCode}>Saltear código</Button>
                <Button className="btnChangeSentence" onClick={changeSentence} disabled={disabled}>¡Obtener frase!</Button>
            </div>
        </>
    )
}

function CardTyping(cS, setDisabled, skip, setSkip) {

    const enableButton = () => {
        setDisabled(false);
        setSkip(true);
    }

    return (
        <Card centered color="dark" className='cardSentence'>
            <Card.Content textAlign="left">
                <Card.Description>
                    {!skip && (
                        <Typing speed={1} onFinishedTyping={enableButton}>
                            {cS.map((item, i) =>
                                <span key={i}>{item}</span>
                            )}
                        </Typing>
                    )}
                    {skip && (
                        <div>
                            {cS.map((item, i) =>
                                <span key={i}>{item}</span>
                            )}
                        </div>
                    )}
                </Card.Description>
            </Card.Content>
        </Card>
    )
}


function spanStyle(color, text, deco = null) {
    if(deco === 1) {
        return <span style={{ color: color, fontWeight: "bold", textDecoration: "underline", fontStyle: "italic" }}>{text}</span>;
    } else {
        return <span style={{ color: color }}>{text}</span>;
    }
}

const codeShow = [
    spanStyle('red', ' import'),
    ' React, ',
    spanStyle('yellow', '{'),
    ' useState ',
    spanStyle('yellow', '}'),
    spanStyle('red', ' from '),
    spanStyle('yellow', ' \'react\'; '),
    <br />,
    spanStyle('red', ' import'),
    ' funnySentences ',
    spanStyle('red', ' from '),
    spanStyle('yellow', ' \'../utils/FS.json\'; '),
    <br />,
    <br />,
    spanStyle('red', ' export default '),
    spanStyle('#28ebf8', 'function '),
    spanStyle('rgb(42, 215, 4)', 'FunnySentences'),
    spanStyle('yellow', '() {'),
    <br />,
    <span className='firstIndent' />,
    spanStyle('#28ebf8', 'const '),
    spanStyle('rgb(233, 157, 255)', '['),
    'sentence, setSentence',
    spanStyle('rgb(233, 157, 255)', ']'),
    spanStyle('red', ' = '),
    spanStyle('rgb(42, 215, 4)', 'useState'),
    spanStyle('rgb(233, 157, 255)', '('),
    spanStyle('red', '-'),
    spanStyle('rgb(233, 157, 255)', '1'),
    spanStyle('rgb(233, 157, 255)', ')'),
    ';',
    <br />,
    <span className='firstIndent' />,
    spanStyle('#28ebf8', 'const '),
    spanStyle('rgb(42, 215, 4)', 'changeSentence'),
    spanStyle('red', ' = '),
    spanStyle('rgb(233, 157, 255)', '()'),
    spanStyle('#28ebf8', ' => '),
    spanStyle('rgb(233, 157, 255)', '{'),
    <br />,
    <span className='twoIndent' />,
    spanStyle('#28ebf8', 'const '),
    'values = ',
    spanStyle('#28ebf8', 'Object', 1),
    spanStyle('#28ebf8', '.keys('),
    'funnySentences',
    spanStyle('#28ebf8', ')'),
    ';',
    <br />,
    <span className='twoIndent' />,
    spanStyle('rgb(42, 215, 4)', 'setSentence'),
    spanStyle('#28ebf8', '('),
    'values',
    spanStyle('yellow', '['),
    spanStyle('#28ebf8', 'parseInt'),
    spanStyle('rgb(233, 157, 255)', '('),
    'Math.',
    spanStyle('#28ebf8', 'random()'),
    spanStyle('red', ' * '),
    'values.length',
    spanStyle('rgb(233, 157, 255)', ')'),
    spanStyle('yellow', ']'),
    spanStyle('#28ebf8', ')'),
    ';',
    <br />,
    <span className='firstIndent' />,
    spanStyle('rgb(233, 157, 255)', '}'),
    <br />,
    <br />,
    <span className='firstIndent' />,
    spanStyle('red', 'return'),
    spanStyle('rgb(233, 157, 255)', ' ('),
    <br />,
    <span className='twoIndent' />,
    '<>',
    <br />,
    <span className='threeIndent' />,
    '<',
    spanStyle('red', 'div'),
    '>',
    spanStyle('#28ebf8', '{'),
    'funnySentences',
    spanStyle('yellow', '['),
    'sentence',
    spanStyle('yellow', ']'),
    spanStyle('#28ebf8', '}'),
    '<',
    spanStyle('red', 'div'),
    '>',
    <br />,
    <span className='threeIndent' />,
    '<',
    spanStyle('red', 'button '),
    spanStyle('rgb(42, 215, 4)', 'onClick'),
    spanStyle('red', '='),
    spanStyle('#28ebf8', '{'),
    spanStyle('rgb(42, 215, 4)', 'changeSentence'),
    spanStyle('#28ebf8', '}'),
    '>',
    'Get phrase!',
    '</',
    spanStyle('red', 'button'),
    '>',
    <br />,
    <span className='twoIndent' />,
    '</>',
    <br />,
    <span className='firstIndent' />,
    spanStyle('rgb(233, 157, 255)', ' )'),
    <br />,
    spanStyle('yellow', '}')
];
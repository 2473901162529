/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';

import ThemeContext from '../../context/ThemeContext';

import ScrollAnimation from 'react-animate-on-scroll';

import { translate } from 'react-switch-lang';
import { Grid, Header, Icon } from 'semantic-ui-react';

import pdfFile from "../../assets/condiciones_fullstack.pdf";

import moment from 'moment';

import "../../assets/scss/Footer.scss";

function Footer(props) {
    const { t } = props;

    const { theme } = useContext(ThemeContext);

    const goToHead = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }

    return(
        <footer id="footer" className={theme === "dark" ? "footer footer__dark" : "footer footer__light"}>
            <Grid className="noMargin">
                <Grid.Column width={16} className="gridColumnFooter gridColumnFooter__light">
                    <Header as='h2' className="titleFooter">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ScrollAnimation animateIn="fadeInLeft">
                                <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>Tomás</span>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <Icon name="react" className="iconTitleFooter" />
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInRight">
                                <span className="colorTitleFooter">Busquets</span>
                            </ScrollAnimation>
                        </div>
                        <div className="iconsFooter">
                            <a className={theme === "dark" ? "noDecorationA textStyle__dark" : "noDecorationA textStyle__light"} rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/tom%C3%A1s-busquets-33672a8a/" title="LinkedIn">
                                <Icon name="linkedin" />
                            </a>
                            <a className={theme === "dark" ? "noDecorationA textStyle__dark" : "noDecorationA textStyle__light"} rel="noopener noreferrer" target="_blank" href="mailto:contact@tomasbusquets.com" title="email">
                                <Icon name="at" />
                            </a>
                        </div>
                        <div className={theme==="dark" ? "smallFooterCopyright textStyle__dark" : "smallFooterCopyright textStyle__light"}>
                            © Copyright {moment().format("YYYY")} - Tomás Busquets
                            {/* <a href={pdfFile} target="_blank" className={theme==="dark" ? "styleLink styleLink__dark" : "styleLink styleLink__light"}>{t('CONDITIONS_JOB')}</a> */}
                        </div>
                        <div  onClick={() => goToHead()} className={theme==="dark" ? "smallGoToHead textStyle__dark" : "smallGoToHead textStyle__light"}>
                            {t('GO_UP')}
                        </div>
                    </Header>
                </Grid.Column>
            </Grid>
        </footer>
    );
}

export default translate(Footer);
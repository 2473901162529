import React, { useContext } from 'react';
import { translate } from 'react-switch-lang';

import ThemeContext from '../../context/ThemeContext';

import ScrollAnimation from 'react-animate-on-scroll';

import { isMobile } from "react-device-detect"; 

import es from "../../assets/images/es.png";
import de from "../../assets/images/de.png";
import us from "../../assets/images/us.png";

import { Grid, Image, Progress, Icon } from 'semantic-ui-react';

function ExpLanguages(props) {

    const { t } = props;

    const { theme } = useContext(ThemeContext);

    return (
        <Grid className={isMobile ? theme==="dark" ? "noMargin gridMobile gridMobile__dark" : "noMargin gridMobile gridMobile__light" : "noMargin minHeightGrid"}>
            <Grid.Column style={{ paddingRight: isMobile ? 10 : 50, paddingTop: isMobile ? 50 : 20 }} width={isMobile ? 16 : 5} id="gridColumn__ExpLanguages" className={theme === "dark" ? "gridColumnContent__1 gridColumnContent__1__dark" : "gridColumnContent__1 gridColumnContent__1__light"}>
                <ScrollAnimation animateIn="slideInRight" animateOnce={true} delay={200}>
                {!isMobile &&
                    <>
                        <div className="strongText titleGridColumm">{t('LANGUAGES')}</div>
                        <div>{t('CONTENT_LANGUAGE')}</div>
                        <Icon name="language" className={theme === "dark" ? "absoluteIconGrid absoluteIconGrid__dark" : "absoluteIconGrid absoluteIconGrid__light"} />
                    </>
                }
                {isMobile &&
                    <div className="gridMobileContent">
                        <div className="strongText titleGridColumm titleGridColummMobile">{t('LANGUAGES')}</div>
                        <div>{t('CONTENT_LANGUAGE')}</div>
                        <Icon name="language" className={theme === "dark" ? "absoluteIconGridMobile absoluteIconGridMobile__dark" : "absoluteIconGridMobile absoluteIconGridMobile__light"} />
                    </div>
                }
                </ScrollAnimation>
            </Grid.Column>
            <Grid.Column width={isMobile ? 16 : 11} className={theme === "dark" ? "gridColumnContent__2 gridColumnContent__2__dark" : "gridColumnContent__2 gridColumnContent__2__light"}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={200}>
                <div className={isMobile ? "gridLanguagesMobile" : "gridLanguages"}>
                    <Grid>
                        {!isMobile &&
                            <Grid.Column>
                                <Image src={es} title="Español" alt="Español" className="imgFlagLang" />
                            </Grid.Column>
                        }
                        <Grid.Column width={isMobile ? 16 : 14} className="progressLang">
                            <Progress percent={100} size='tiny' color='green' className={theme === "dark" ? "progressBar progressBar__dark" : "progressBar progressBar__light"} />
                            {isMobile &&
                                <Image src={es} title="Español" alt="Español" className="imgFlagLang" />
                            }
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        {!isMobile &&
                            <Grid.Column>
                                <Image src={us} title="English" alt="English" className="imgFlagLang" />
                            </Grid.Column>
                        }
                        <Grid.Column width={isMobile ? 16 : 14} className="progressLang">
                            <Progress percent={50} size='tiny' color='yellow' className={theme === "dark" ? "progressBar progressBar__dark" : "progressBar progressBar__light"} />
                            {isMobile &&
                                <Image src={us} title="English" alt="English" className="imgFlagLang" />
                            }
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        {!isMobile &&
                            <Grid.Column>
                                <Image src={de} title="Deutsch" alt="Deutsch" className="imgFlagLang" />
                            </Grid.Column>
                        }
                        <Grid.Column width={isMobile ? 16 : 14} className="progressLang">
                            <Progress percent={50} size='tiny' color='yellow' className={theme === "dark" ? "progressBar progressBar__dark" : "progressBar progressBar__light"} />
                            {isMobile &&
                                <Image src={de} title="Deutsch" alt="Deutsch" className="imgFlagLang" />
                            }
                        </Grid.Column>
                    </Grid>
                </div>
                </ScrollAnimation>
            </Grid.Column>
        </Grid>
    )
}

export default translate(ExpLanguages);
import React, { useContext } from 'react';
import { translate } from 'react-switch-lang';

import { NavLink } from "react-router-dom";

import { Grid, Menu } from "semantic-ui-react";

import ThemeContext from '../../context/ThemeContext';

import { isMobile } from "react-device-detect";

import "./MenuHeader.scss";

function MenuHeader(props) {

    const { t, activeItem, setActiveItem } = props;

    const { theme } = useContext(ThemeContext);

    return (
        <Grid className={theme === "dark" ? "gridMenuHeader bgStyle__dark textStyle__dark" : "gridMenuHeader bgStyle__light textStyle__light"}>
            <Grid.Column style={{ width: isMobile ? "100%" : "70%", margin: "0 auto" }}>
                <Menu widths={4} stackable fluid pointing secondary className={theme === "dark" ? "menuItemsHeader textStyle__dark" : "menuItemsHeader textStyle__light"}>
                    <Menu.Item
                        name={t('HOME')}
                        active={activeItem === "home"}
                        as={NavLink}
                        exact to="/"
                        onClick={() => setActiveItem("home")}
                    />
                    <Menu.Item
                        name={t('CV')}
                        active={activeItem === "cv"}
                        as={NavLink}
                        exact to="/cv"
                        onClick={() => setActiveItem("cv")}
                    />
                    <Menu.Item
                        name={t('PORTFOLIO')}
                        active={activeItem === "portfolio"}
                        as={NavLink}
                        exact to="/portfolio"
                        onClick={() => setActiveItem("portfolio")}
                    />
                    <Menu.Item
                        name={t('CONTACT')}
                        active={activeItem === "contact"}
                        as={NavLink}
                        exact to="/contact"
                        onClick={() => setActiveItem("contact")}
                    />
                </Menu>
            </Grid.Column>
        </Grid>
    )
}

export default translate(MenuHeader);

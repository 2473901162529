import React, { useRef } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from "./components/Home";
import CV from "./components/CV";
import Portfolio from "./components/Portfolio";
import PortfolioPage from "./components/PortfolioPage";
//los componentes necesita el Route path
//los componentes que tiene esta class
import Header from './components/base/Header';
import Contact from './components/Contact';
import Footer from './components/base/Footer';
import ErrorPage from './components/base/ErrorPage';

export default function Router(props) {

    const { langSelected, setLangSelected, getBasename } = props;

    const recaptchaInstance = useRef(null);

    return (
        <BrowserRouter basename={getBasename(window.location.pathname)}>
            <Header langSelected={langSelected} setLangSelected={setLangSelected} recaptchaInstance={recaptchaInstance} />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/cv" component={CV} />
                <Route exact path="/portfolio" component={PortfolioPage} />
                <Route exact path="/contact" render={(props) => <Contact {...props} recaptchaInstance={recaptchaInstance} />} />
                <Route component={ErrorPage} />
            </Switch>
            <Footer />
        </BrowserRouter>
    );
}

import React, { useContext } from 'react';
import { translate } from 'react-switch-lang';

import ThemeContext from '../../context/ThemeContext';

import ScrollAnimation from 'react-animate-on-scroll';

import { isMobile } from "react-device-detect"; 

import { LazyLoadImage } from 'react-lazy-load-image-component';

import edenia from "../../assets/images/portfolio/edenia.jpg";
import turnero from "../../assets/images/portfolio/turnero/0.png";

import { Grid, Label, Icon, Segment, Card } from 'semantic-ui-react';

function Portfolio(props) {

    const { t } = props;

    const { theme } = useContext(ThemeContext);

    return (
        <div>{null}</div>
        // <Grid className={isMobile ? theme==="dark" ? "noMargin gridMobile gridMobile__dark" : "noMargin gridMobile gridMobile__light" : "noMargin minHeightGrid"}>
        //     <Grid.Column style={{ paddingRight: isMobile ? 10 : 50, paddingTop: isMobile ? 50 : 20 }} width={isMobile ? 16 : 5} id="gridColumn__Portfolio" className={theme === "dark" ? "gridColumnContent__1 gridColumnContent__1__dark" : "gridColumnContent__1 gridColumnContent__1__light"}>
        //         <ScrollAnimation animateIn="slideInRight" animateOnce={true} delay={50}>
        //             {!isMobile &&
        //                 <>
        //                     <div className="strongText titleGridColumm">{t('PORTFOLIO')}</div>
        //                     <div>{t('CONTENT_PORTFOLIO')}</div>
        //                     <Icon name="address book outline" className={theme === "dark" ? "absoluteIconGrid absoluteIconGrid__dark" : "absoluteIconGrid absoluteIconGrid__light"} />
        //                 </>
        //             }
        //             {isMobile &&
        //                 <div className="gridMobileContent">
        //                     <div className="strongText titleGridColumm titleGridColummMobile">{t('PORTFOLIO')}</div>
        //                     <div>{t('CONTENT_PORTFOLIO')}</div>
        //                     <Icon name="address book outline" className={theme === "dark" ? "absoluteIconGridMobile absoluteIconGridMobile__dark" : "absoluteIconGridMobile absoluteIconGridMobile__light"} />
        //                 </div>
        //             }
        //         </ScrollAnimation>
        //     </Grid.Column>

        //     <Grid.Column width={isMobile ? 16 : 11} className={theme === "dark" ? "gridColumnContent__2 gridColumnContent__2__dark" : "gridColumnContent__2 gridColumnContent__2__light"}>
        //         <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={50}>
        //         <div className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
        //             <Card.Group>
        //                 <Card fluid className="portfolioCard">
        //                     <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
        //                         <Card.Header><Icon name="double angle right" /><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_1')}</span></Card.Header>
        //                         <div className={isMobile ? "contentCardPortfolio contentCardPortfolio__Mobile" : "contentCardPortfolio contentCardPortfolio__PC"}>
        //                             <Card.Meta>{t('DESCRIPTION_PORTFOLIO_1')}</Card.Meta>
        //                             <Segment floated="left" basic className={isMobile ? "imgWithLabelMobile" : "imgWithLabel"}>
        //                                 <LazyLoadImage
        //                                     className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
        //                                     src={edenia}
        //                                     effect="blur"
        //                                 />
        //                                 <div className="absoluteLabel">
        //                                     <Label color="blue" ribbon='right'>
        //                                         <Icon name='react' /> React JS + Hooks
        //                                     </Label>
        //                                     <Label color="pink" ribbon='right'>
        //                                         <Icon name='sass' /> CSS Sass
        //                                     </Label>
        //                                     <Label color="green" ribbon='right'>
        //                                         <Icon name='node js' /> NodeJS
        //                                     </Label>
        //                                 </div>
        //                             </Segment>
        //                         </div>
        //                     </Card.Content>
        //                 </Card>
        //                 <Card fluid className="portfolioCard">
        //                     <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
        //                         <Card.Header><Icon name="double angle right" /><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_2')}</span></Card.Header>
        //                         <div className={isMobile ? "contentCardPortfolio contentCardPortfolio__Mobile" : "contentCardPortfolio contentCardPortfolio__PC"}>
        //                             <Card.Meta>{t('DESCRIPTION_PORTFOLIO_2')}</Card.Meta>
        //                             <Segment floated="left" basic className={isMobile ? "imgWithLabelMobile" : "imgWithLabel"}>
        //                                 <LazyLoadImage
        //                                     className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
        //                                     src={turnero}
        //                                     effect="blur"
        //                                 />
        //                                 <div className="absoluteLabel">
        //                                     <Label color="blue" ribbon='right'>
        //                                         <Icon name='react' /> React JS + Hooks
        //                                     </Label>
        //                                     <Label color="pink" ribbon='right'>
        //                                         <Icon name='sass' /> CSS Sass
        //                                     </Label>
        //                                     <Label color="green" ribbon='right'>
        //                                         <Icon name='node js' /> NodeJS
        //                                     </Label>
        //                                     <Label color="red" ribbon='right'>
        //                                         <Icon name='node' /> Electron + Sockets
        //                                     </Label>
        //                                 </div>
        //                             </Segment>
        //                         </div>
        //                     </Card.Content>
        //                 </Card>
        //             </Card.Group>
        //         </div>
        //         </ScrollAnimation>
        //     </Grid.Column>
        // </Grid>
    )
}

export default translate(Portfolio);
import React, { useContext } from 'react';
import { translate } from 'react-switch-lang';

import ThemeContext from '../../context/ThemeContext';

import ScrollAnimation from 'react-animate-on-scroll';

import { isMobile } from "react-device-detect"; 

import { Grid, Icon } from 'semantic-ui-react';

function Intro(props) {

    const { t } = props;

    const { theme } = useContext(ThemeContext);

    return (
        <Grid className={isMobile ? theme==="dark" ? "noMargin gridMobile gridMobile__dark" : "noMargin gridMobile gridMobile__light" : "noMargin minHeightGrid"}>
            <Grid.Column style={{ paddingRight: isMobile ? 10 : 50, paddingTop: isMobile ? 50 : 20 }} width={isMobile ? 16 : 5} id="gridColumn__Intro" className={theme === "dark" ? "gridColumnContent__1 gridColumnContent__1__dark" : "gridColumnContent__1 gridColumnContent__1__light"}>
                <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
                    {!isMobile &&
                        <>
                            <div className="strongText titleGridColumm">{t('INTRO')}</div>
                            <div>{t('INTRO_SUB')}</div>
                            <Icon name="user" className={theme === "dark" ? "absoluteIconGrid absoluteIconGrid__dark" : "absoluteIconGrid absoluteIconGrid__light"} />
                        </>
                    }
                    {isMobile &&
                        <div className="gridMobileContent">
                            <div className="strongText titleGridColumm titleGridColummMobile">{t('INTRO')}</div>
                            <div>{t('INTRO_SUB')}</div>
                            <Icon name="user" className={theme === "dark" ? "absoluteIconGridMobile absoluteIconGridMobile__dark" : "absoluteIconGridMobile absoluteIconGridMobile__light"} />
                        </div>
                    }
                </ScrollAnimation>
            </Grid.Column>
            <Grid.Column width={isMobile ? 16 : 11} className={theme === "dark" ? "gridColumnContent__2 gridColumnContent__2__dark" : "gridColumnContent__2 gridColumnContent__2__light"}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                        {t('INTRO_TEXT')}
                    </span>
                </ScrollAnimation>
            </Grid.Column>
        </Grid>
    )
}

export default translate(Intro);

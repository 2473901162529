import React from 'react';

import { Redirect } from "react-router-dom";

import { translate } from 'react-switch-lang';

function ErrorPage(props) {

    const { t } = props;

    return (
        <Redirect to="/" />
    );

}

export default translate(ErrorPage);
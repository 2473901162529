import React, { useContext, useState } from 'react';
import { translate } from 'react-switch-lang';

import ThemeContext from '../../context/ThemeContext';

import ScrollAnimation from 'react-animate-on-scroll';

import Lightbox from 'react-image-lightbox';

import { isMobile } from "react-device-detect"; 

import { LazyLoadImage } from 'react-lazy-load-image-component';

import edenia from "../../assets/images/portfolio/edenia.jpg";
import rpdigitales from "../../assets/images/portfolio/rpdigitales.png";
import nottodiefor from "../../assets/images/portfolio/nottodiefor.png";
import tykio from "../../assets/images/portfolio/tykio.png";
import vdog from "../../assets/images/portfolio/v-dog.png";
import botgenes from "../../assets/images/portfolio/botgenes.png";
import turnero from "../../assets/images/portfolio/turnero/0.png";

import { Grid, Label, Icon, Segment, Card } from 'semantic-ui-react';

function PortfolioPage(props) {

    const { t } = props;

    const { theme } = useContext(ThemeContext);

    const [indexImgGallery, setIndexImgGallery] = useState(0);
    const [openGalleryImg, setOpenGalleryImg] = useState(false);
    const [allImgs, setAllImgs] = useState([]);

    const SetGallery = (list, cant) => {
        let auxImgs = [];
        {Array.from(Array(cant), (e, i) => (
            auxImgs.push(require(`../../assets/images/portfolio/${list}/${i}.png`))
        ))}
        setAllImgs(auxImgs);
        setOpenGalleryImg(true);
    }

    return (
        <>
        <Grid container columns="equal" className={isMobile ? theme==="dark" ? "noMargin centerGrid gridMobile gridMobile__dark" : "noMargin gridMobile centerGrid gridMobile__light" : "noMargin centerGrid minHeightGrid"}>
            <Grid.Column className={theme==="dark" ? "minColumnGrid columnGrid__BG1__Dark" : "minColumnGrid columnGrid__BG1__Light"}>
                <Card fluid className="portfolioCard">
                    <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
                        <Card.Header className="centerTitleHeader"><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_1')}</span></Card.Header>
                        <div className="cardInPortfolio">
                            <Segment basic className="imgLabelSegmentPortfolio">
                                    <LazyLoadImage
                                        className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
                                        src={edenia}
                                        effect="blur"
                                        onClick={() => window.open('https://www.edeniahoteles.com.ar', '_blank')}
                                    />
                                    <div className="relativeLabelPortfolio">
                                        <Label color="blue">
                                            <Icon name='react' /> React JS + Hooks
                                        </Label>
                                        <Label color="pink">
                                            <Icon name='sass' /> CSS Sass
                                        </Label>
                                        <Label color="green">
                                            <Icon name='node js' /> NodeJS
                                        </Label>
                                    </div>
                            </Segment>
                            <Card.Meta>{t('DESCRIPTION_PORTFOLIO_1')}</Card.Meta>
                        </div>
                    </Card.Content>
                </Card>
            </Grid.Column>
            <Grid.Column className={theme==="dark" ? "minColumnGrid columnGrid__BG2__Dark" : "minColumnGrid columnGrid__BG2__Light"}>
                <Card fluid className="portfolioCard">
                    <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
                        <Card.Header className="centerTitleHeader"><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_2')}</span></Card.Header>
                        <div className="cardInPortfolio">
                            <Segment basic className="imgLabelSegmentPortfolio">
                                    <LazyLoadImage
                                        className={theme === "dark" ? "portfolioImg portfolioImg__max portfolioImg__dark" : "portfolioImg portfolioImg__max portfolioImg__light"}
                                        src={turnero}
                                        effect="blur"
                                        onClick={() => SetGallery("turnero", 9)}
                                    />
                                    <div className="relativeLabelPortfolio">
                                        <Label color="blue">
                                            <Icon name='react' /> React JS + Hooks
                                        </Label>
                                        <Label color="pink">
                                            <Icon name='sass' /> CSS Sass
                                        </Label>
                                        <Label color="green">
                                            <Icon name='node js' /> NodeJS
                                        </Label>
                                        <Label color="red">
                                            <Icon name='node' /> Electron + Sockets
                                        </Label>
                                    </div>
                            </Segment>
                            <Card.Meta>{t('DESCRIPTION_PORTFOLIO_2')}</Card.Meta>
                        </div>
                    </Card.Content>
                </Card>
            </Grid.Column>

            <Grid.Column className={theme==="dark" ? "minColumnGrid columnGrid__BG1__Dark" : "minColumnGrid columnGrid__BG1__Light"}>
                <Card fluid className="portfolioCard">
                    <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
                        <Card.Header className="centerTitleHeader"><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_3')}</span></Card.Header>
                        <div className="cardInPortfolio">
                            <Segment basic className="imgLabelSegmentPortfolio">
                                    <LazyLoadImage
                                        className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
                                        src={rpdigitales}
                                        effect="blur"
                                        onClick={() => window.open('https://www.rpdigitales.com.ar', '_blank')}
                                    />
                                    <div className="relativeLabelPortfolio">
                                        <Label color="blue">
                                            <Icon name='react' /> React JS + Hooks
                                        </Label>
                                        <Label color="pink">
                                            <Icon name='sass' /> CSS Sass
                                        </Label>
                                        <Label color="green">
                                            <Icon name='node js' /> NodeJS
                                        </Label>
                                    </div>
                            </Segment>
                            <Card.Meta>{t('DESCRIPTION_PORTFOLIO_3')}</Card.Meta>
                        </div>
                    </Card.Content>
                </Card>
            </Grid.Column>
            <Grid.Column className={theme==="dark" ? "minColumnGrid columnGrid__BG2__Dark" : "minColumnGrid columnGrid__BG2__Light"}>
                <Card fluid className="portfolioCard">
                    <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
                        <Card.Header className="centerTitleHeader"><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_4')}</span></Card.Header>
                        <div className="cardInPortfolio">
                            <Segment basic className="imgLabelSegmentPortfolio">
                                    <LazyLoadImage
                                        className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
                                        src={nottodiefor}
                                        effect="blur"
                                        onClick={() => window.open('https://www.nottodiefor.us', '_blank')}
                                    />
                                    <div className="relativeLabelPortfolio">
                                        <Label color="grey">
                                            <Icon name='wordpress' /> Wordpress
                                        </Label>
                                        <Label color="pink">
                                            <Icon name='sass' /> CSS
                                        </Label>
                                    </div>
                            </Segment>
                            <Card.Meta>{t('DESCRIPTION_PORTFOLIO_4')}</Card.Meta>
                        </div>
                    </Card.Content>
                </Card>
            </Grid.Column>

            <Grid.Column className={theme==="dark" ? "minColumnGrid columnGrid__BG1__Dark" : "minColumnGrid columnGrid__BG1__Light"}>
                <Card fluid className="portfolioCard">
                    <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
                        <Card.Header className="centerTitleHeader"><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_5')}</span></Card.Header>
                        <div className="cardInPortfolio">
                            <Segment basic className="imgLabelSegmentPortfolio">
                                    <LazyLoadImage
                                        className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
                                        src={tykio}
                                        effect="blur"
                                        onClick={() => window.open('https://www.tykio.com', '_blank')}
                                    />
                                    <div className="relativeLabelPortfolio">
                                        <Label color="blue">
                                            <Icon name='js' /> Javascript + jQuery
                                        </Label>
                                        <Label color="pink">
                                            <Icon name='sass' /> CSS
                                        </Label>
                                        <Label color="green">
                                            <Icon name='php' /> PHP
                                        </Label>
                                    </div>
                            </Segment>
                            <Card.Meta>{t('DESCRIPTION_PORTFOLIO_5')}</Card.Meta>
                        </div>
                    </Card.Content>
                </Card>
            </Grid.Column>
            <Grid.Column className={theme==="dark" ? "minColumnGrid columnGrid__BG2__Dark" : "minColumnGrid columnGrid__BG2__Light"}>
                <Card fluid className="portfolioCard">
                    <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
                        <Card.Header className="centerTitleHeader"><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_6')}</span></Card.Header>
                        <div className="cardInPortfolio">
                            <Segment basic className="imgLabelSegmentPortfolio">
                                    <LazyLoadImage
                                        className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
                                        src={vdog}
                                        effect="blur"
                                        onClick={() => window.open('https://www.v-dog.com', '_blank')}
                                    />
                                    <div className="relativeLabelPortfolio">
                                        <Label color="grey">
                                            <Icon name='shop' /> Shopify
                                        </Label>
                                        <Label color="pink">
                                            <Icon name='sass' /> CSS
                                        </Label>
                                    </div>
                            </Segment>
                            <Card.Meta>{t('DESCRIPTION_PORTFOLIO_6')}</Card.Meta>
                        </div>
                    </Card.Content>
                </Card>
            </Grid.Column>

            <Grid.Column className={theme==="dark" ? "minColumnGrid columnGrid__BG2__Dark" : "minColumnGrid columnGrid__BG2__Light"}>
                <Card fluid className="portfolioCard">
                    <Card.Content className={theme === "dark" ? "portfolioContent textStyle__dark" : "portfolioContent textStyle__light"}>
                        <Card.Header className="centerTitleHeader"><span className="titleSpanPortfolio">{t('TITLE_PORTFOLIO_7')}</span></Card.Header>
                        <div className="cardInPortfolio">
                            <Segment basic className="imgLabelSegmentPortfolio">
                                    <LazyLoadImage
                                        className={theme === "dark" ? "portfolioImg portfolioImg__dark" : "portfolioImg portfolioImg__light"}
                                        src={botgenes}
                                        effect="blur"
                                        onClick={() => window.open('https://www.botgenes.com', '_blank')}
                                    />
                                    <div className="relativeLabelPortfolio">
                                        <Label color="blue">
                                            <Icon name='js' /> Javascript + jQuery
                                        </Label>
                                        <Label color="pink">
                                            <Icon name='sass' /> CSS
                                        </Label>
                                        <Label color="green">
                                            <Icon name='php' /> PHP
                                        </Label>
                                    </div>
                            </Segment>
                            <Card.Meta>{t('DESCRIPTION_PORTFOLIO_7')}</Card.Meta>
                        </div>
                    </Card.Content>
                </Card>
            </Grid.Column>

        </Grid>
        {openGalleryImg && (
            <Lightbox
                mainSrc={allImgs[indexImgGallery]}
                nextSrc={allImgs[(indexImgGallery + 1) % allImgs.length]}
                prevSrc={allImgs[(indexImgGallery + allImgs.length - 1) % allImgs.length]}
                onCloseRequest={() => setOpenGalleryImg(false)}
                onMovePrevRequest={() => setIndexImgGallery((indexImgGallery + allImgs.length - 1) % allImgs.length)}
                onMoveNextRequest={() => setIndexImgGallery((indexImgGallery + 1) % allImgs.length)}
            />
        )}
        </>
    )
}

export default translate(PortfolioPage);
import React, { useState, useEffect } from 'react';
import './assets/scss/App.scss';
import ThemeContext from './context/ThemeContext';

import Router from './Router';

import {
  setTranslations,
  setDefaultLanguage,
  setLanguage
} from 'react-switch-lang';

//languagues
import es from './utils/json/langs/es.json';
import us from './utils/json/langs/us.json';
import de from './utils/json/langs/de.json';


function App() {

  setTranslations({ es, us, de });

  const getBasename = path => path.substr(0, path.lastIndexOf('/'));

  const [theme, setTheme] = useState(localStorage.getItem('themeCVTomas') ? localStorage.getItem('themeCVTomas') : 'dark');
  const value = { theme, setTheme };
  const [langSelected, setLangSelected] = useState(localStorage.getItem('languageCVTomas') ? localStorage.getItem('languageCVTomas') : 'es');

  useEffect(() => {
    if(!localStorage.getItem('languageCVTomas')) {
      setDefaultLanguage("es");
      setLanguage("es");
    } else {
      setDefaultLanguage(localStorage.getItem('languageCVTomas'));
      setLanguage(localStorage.getItem('languageCVTomas'));
    }
  }, []);

  return (
    <ThemeContext.Provider value={value}>
      <div className={theme === "dark" ? "App App__dark" : "App App__light"}>
        <div className={theme === "dark" ? "AppContent AppContent__dark" : "AppContent AppContent__light"} columns={1}>
          <Router setLangSelected={setLangSelected} langSelected={langSelected} getBasename={getBasename} />
        </div>
      </div> 
    </ThemeContext.Provider>
  );
}

export default App;

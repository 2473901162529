import React from 'react';

//components
import Intro from "../Intro";
import ExpLab from "../ExpLab";
import ExpLanguages from "../ExpLanguages";
import Skills from "../Skills";
import Portfolio from "../Portfolio";

export default function CV() {
    return (
        <section id="contentHome">
            <Intro />
            <ExpLab />
            <ExpLanguages />
            <Skills />
            <Portfolio />
        </section>
    )
}

import React, { useContext } from 'react';
import { translate } from 'react-switch-lang';

import ThemeContext from '../../context/ThemeContext';

import ScrollAnimation from 'react-animate-on-scroll';

import { isMobile } from "react-device-detect"; 

import { Grid, List, Icon, Card, Popup } from 'semantic-ui-react';

function Skills(props) {

    const { t } = props;

    const { theme } = useContext(ThemeContext);

    return (
        <Grid className={isMobile ? theme==="dark" ? "noMargin gridMobile gridMobile__dark" : "noMargin gridMobile gridMobile__light" : "noMargin minHeightGrid"}>
            <Grid.Column style={{ paddingRight: isMobile ? 10 : 50, paddingTop: isMobile ? 50 : 20 }} width={isMobile ? 16 : 5} id="gridColumn__Skills" className={theme === "dark" ? "gridColumnContent__1 gridColumnContent__1__dark_secondary" : "gridColumnContent__1 gridColumnContent__1__light_secondary"}>
                <ScrollAnimation animateIn="slideInRight" animateOnce={true} delay={50}>
                {!isMobile &&
                    <>
                        <div className="strongText titleGridColumm">Skills</div>
                        <div>{t('CONTENT_SKILLS')}</div>
                        <Icon name="code" className={theme === "dark" ? "absoluteIconGrid absoluteIconGrid__dark" : "absoluteIconGrid absoluteIconGrid__light"} />
                    </>
                }
                {isMobile &&
                    <div className="gridMobileContent">
                        <div className="strongText titleGridColumm titleGridColummMobile">Skills</div>
                        <div>{t('CONTENT_SKILLS')}</div>
                        <Icon name="code" className={theme === "dark" ? "absoluteIconGridMobile absoluteIconGridMobile__dark" : "absoluteIconGridMobile absoluteIconGridMobile__light"} />
                    </div>
                }
                </ScrollAnimation>
            </Grid.Column>
            <Grid.Column width={isMobile ? 16 : 11} className={theme === "dark" ? "gridColumnContent__2 gridColumnContent__2__dark_secondary" : "gridColumnContent__2 gridColumnContent__2__light_secondary"}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={50}>
                <div className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                    <Card.Group>
                        <Card className="skillGroupCard">
                            <Card.Content className={theme === "dark" ? "cardContent textStyle__dark" : "cardContent textStyle__light"}>
                                <Popup
                                    basic
                                    inverted={theme === "dark" ? true : false}
                                    size='tiny'
                                    content={t('CONTENT_FRONTEND')}
                                    key={"frontend"}
                                    header={"Frontend"}
                                    trigger={<Card.Header><div className="smallCircleSkill" /><span className="titleSpanSkill">Frontend</span></Card.Header>}
                                />
                                <Card.Description className={isMobile ? "descriptionSkills descriptionSkills__Mobile" : "descriptionSkills descriptionSkills__PC"}>
                                    <List className="listCheckSkill">
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                HTML 5
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                Javascript + jQuery
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                React + Hooks
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                CSS + SASS + Bootstrap
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card className="skillGroupCard">
                            <Card.Content className={theme === "dark" ? "cardContent textStyle__dark" : "cardContent textStyle__light"}>
                                <Popup
                                    basic
                                    inverted={theme === "dark" ? true : false}
                                    size='tiny'
                                    content={t('CONTENT_BACKEND')}
                                    key={"backend"}
                                    header={"Backend"}
                                    trigger={<Card.Header><div className="smallCircleSkill" /><span className="titleSpanSkill">Backend</span></Card.Header>}
                                />
                                <Card.Description className={isMobile ? "descriptionSkills descriptionSkills__Mobile" : "descriptionSkills descriptionSkills__PC"}>
                                    <List className="listCheckSkill">
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                NodeJS
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                Typescript
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                PHP
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card className="skillGroupCard">
                            <Card.Content className={theme === "dark" ? "cardContent textStyle__dark" : "cardContent textStyle__light"}>
                                <Popup
                                    basic
                                    inverted={theme === "dark" ? true : false}
                                    size='tiny'
                                    content={t('CONTENT_DATABASE')}
                                    key={"database"}
                                    header={t('DATABASE')}
                                    trigger={<Card.Header><div className="smallCircleSkill" /><span className="titleSpanSkill">{t('DATABASE')}</span></Card.Header>}
                                />
                                <Card.Description className={isMobile ? "descriptionSkills descriptionSkills__Mobile" : "descriptionSkills descriptionSkills__PC"}>
                                    <List className="listCheckSkill">
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                MongoDB
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                MySQLi
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                Firebase
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card className="skillGroupCard">
                            <Card.Content className={theme === "dark" ? "cardContent textStyle__dark" : "cardContent textStyle__light"}>
                                <Popup
                                    basic
                                    inverted={theme === "dark" ? true : false}
                                    size='tiny'
                                    content={t('CONTENT_MOBILE')}
                                    key={"mobile"}
                                    header={t('MOBILE')}
                                    trigger={<Card.Header><div className="smallCircleSkill" /><span className="titleSpanSkill">{t('MOBILE')}</span></Card.Header>}
                                />
                                <Card.Description className={isMobile ? "descriptionSkills descriptionSkills__Mobile" : "descriptionSkills descriptionSkills__PC"}>
                                    <List className="listCheckSkill">
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                React Native + Hooks
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card className="skillGroupCard">
                            <Card.Content className={theme === "dark" ? "cardContent textStyle__dark" : "cardContent textStyle__light"}>
                                <Card.Header><div className="smallCircleSkill" /><span className="titleSpanSkill">{t('OTHERS')}</span></Card.Header>
                                <Card.Description className={isMobile ? "descriptionSkills descriptionSkills__Mobile" : "descriptionSkills descriptionSkills__PC"}>
                                    <List className="listCheckSkill">
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                Electron
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                Python
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>
                                                <Icon name="checkmark" className="iconListCheck" />
                                                Chatbots
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </div>
                </ScrollAnimation>
            </Grid.Column>
        </Grid>
    )
}

export default translate(Skills);
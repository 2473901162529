import React, { useState, useContext } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import Scramble from 'react-scramble';

import MenuHeader from '../MenuHeader';

import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

import ThemeContext from '../../context/ThemeContext';
import ThemeSwitcher from '../../context/ThemeSwitcher';

import { setLanguage, setDefaultLanguage, translate } from 'react-switch-lang';
import { Grid, Header, Icon, Dropdown, Flag } from 'semantic-ui-react';

import Face from "../../assets/images/face.png";

import "../../assets/scss/Header.scss";

function HeaderPage(props) {

    const { t, langSelected, setLangSelected, recaptchaInstance } = props;
    
    const { theme } = useContext(ThemeContext);

    const [activeItem, setActiveItem] = useState("/home");

    const langOptions = [
        { key: 'es', value: 'es', text: [(<Flag name='es' />),"Español"] },
        { key: 'us', value: 'us', text: [(<Flag name='us' />),"English"] },
        { key: 'de', value: 'de', text: [(<Flag name='de' />),"Deutsch"] }
    ];

    const changeLang = (e, { value }) => {
        setLanguage(value);
        setDefaultLanguage(value);
        setLangSelected(value);
        localStorage.setItem('languageCVTomas', value);
        recaptchaInstance.reset();
    }

    return(
        <header id="header" className="no-print">
            <Grid className={isMobile ? "minHeightMobile noMargin" : "noMargin"}>
                <Grid.Column width={isMobile ? 16 : 5} style={{ maxHeight: isMobile ? "max-content" : 320 }} className={theme === "dark" ? "gridFaceImg centeredGrid centeredGrid__justify gridHeader gridColumn__1 gridColumn__1__dark" : "gridFaceImg centeredGrid centeredGrid__justify gridHeader gridColumn__1 gridColumn__1__light"}>
                    <Link to="/">
                        <LazyLoadImage
                            className="imgHeaderFace"
                            src={Face}
                            alt="Tomás Busquets - contact@tomasbusquets.com"
                            title="Tomás Busquets - contact@tomasbusquets.com"
                        />
                    </Link>
                    <div className="iconsFaceHeader">
                        <Grid centered className={theme === "dark" ? "iconsGrid icons__dark" : "iconsGrid icons__light"}>
                            <Grid.Column>
                                <a className={theme === "dark" ? "noDecorationA textStyle__dark" : "noDecorationA textStyle__light"} rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/tom%C3%A1s-busquets-33672a8a/" title="LinkedIn">
                                    <Icon name="linkedin" />
                                </a>
                            </Grid.Column>
                            <Grid.Column>
                                <a className={theme === "dark" ? "noDecorationA textStyle__dark" : "noDecorationA textStyle__light"} rel="noopener noreferrer" target="_blank" href="mailto:contact@tomasbusquets.com" title="email">
                                    <Icon name="at" />
                                </a>
                            </Grid.Column>
                        </Grid>
                    </div>
                </Grid.Column>
                <Grid.Column width={isMobile ? 16 : 11} style={{ maxHeight: isMobile ? "max-content" : 320 }} className={theme === "dark" ? "centeredGrid gridHeader gridColumn__2 gridColumn__2__dark" : "centeredGrid gridHeader gridColumn__2 gridColumn__2__light"}>
                    <div className="containerTitleHeader">
                        <Header as='h2' className={isMobile ? "titleHeader centerTitle" : "titleHeader"}>
                            <div>
                                <span className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>Tomás</span>
                                {!isMobile && <Icon name="react" className="iconTitleHeader" />}
                            </div>
                            <Header.Subheader className="subtitleHeader">
                            Busquets
                            </Header.Subheader>
                            <Header.Subheader className={theme === "dark" ? "subSubHeader textStyle__dark" : "subSubHeader textStyle__light"}>
                                <Scramble
                                    mouseEnterTrigger="pause"
                                    mouseLeaveTrigger="start"
                                    speed="medium"
                                    autoStart
                                    text="Web developer FullStack"
                                    steps={[
                                    {
                                        roll: 8,
                                        action: '+',
                                        type: 'all',
                                    },
                                    {
                                        action: '-',
                                        type: 'forward',
                                    }
                                    ]}
                                />
                            </Header.Subheader>
                        </Header>
                    </div>
                    <div className="infoHeader">
                        <Grid>
                            <Grid.Column width={isMobile ? 16 : 5}>
                                <div className={theme === "dark" ? "strongText textStyle__dark" : "strongText textStyle__light"}>{t('UBICATION')} <Icon name="map marker alternate" /></div> 
                                <div className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>Buenos Aires, {t('ARGENTINA')} <Flag name='ar' /></div>
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 16 : 5}>
                                <div className={theme === "dark" ? "strongText textStyle__dark" : "strongText textStyle__light"}>Web <Icon name="globe" /></div> 
                                <div className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                                    www.tomasbusquets.com        
                                </div>
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 16 : 5}>
                                <div className={theme === "dark" ? "strongText textStyle__dark" : "strongText textStyle__light"}>Email <Icon name="at" /></div> 
                                <div className={theme === "dark" ? "textStyle__dark" : "textStyle__light"}>
                                    contact@tomasbusquets.com
                                </div>
                            </Grid.Column>
                        </Grid>
                    </div>
                    <div className={isMobile ? "dropChangeLangAndThema__Mobile" : "dropChangeLangAndThema__PC"}>
                        <Dropdown
                            button
                            className={theme === "dark" ? 'icon iconDropdown textStyle__dark' : 'icon iconDropdown textStyle__light'}
                            placeholder='Select language'
                            floating
                            labeled
                            search
                            defaultValue={langSelected}
                            options={langOptions}
                            onChange={changeLang}
                        />
                        <ThemeSwitcher />
                    </div>
                </Grid.Column>
            </Grid>
            <MenuHeader setActiveItem={setActiveItem} activeItem={activeItem} />
        </header>
    );
}

export default translate(HeaderPage);